<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">机构结算统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="结算单状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">结算单状态:</span>
              <el-select
                size="small"
                v-model="auditType"
                placeholder="请选择结算单状态"
                clearable
                ><el-option
                  v-for="item in statementList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="培训机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >培训机构名称:</span
              >
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="班级结清状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >班级结清状态:</span
              >

              <el-select
                size="small"
                v-model="settleState"
                placeholder="请选择班级结清状态"
                clearable
                ><el-option
                  v-for="item in settleStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="是否开票" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">是否开票:</span>
              <el-select
                size="small"
                v-model="billing"
                placeholder="请选择是否开票"
                clearable
              >
                <el-option
                  v-for="item in billingTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">行政区划:</span>
              <el-cascader
                clearable
                filterable
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">培训类型:</span>
              <el-cascader
                style="width: 100%"
                size="small"
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="trainTypeId"
                :options="trainTypeList"
                clearable
                @change="handleTrainType"
              ></el-cascader>
            </div>
          </div>
          <div class="searchbox">
            <div title="班级结束日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >班级结束日期:</span
              >
              <el-date-picker
                clearable
                size="small"
                v-model="signingTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>

            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="getExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="培训机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                fixed
                min-width="260px"
              />
              <el-table-column
                label="付款机构名称"
                align="left"
                show-overflow-tooltip
                prop="signCompName"
                min-width="260px"
              />
              <el-table-column
                label="省"
                align="left"
                show-overflow-tooltip
                prop="provinceName"
                min-width="80px"
              />
              <el-table-column
                label="市"
                align="left"
                show-overflow-tooltip
                prop="cityName"
                min-width="80px"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                minWidth="130"
                prop="trainTypeName"
              />
              <el-table-column
                label="已结算班级数量"
                align="left"
                show-overflow-tooltip
                minWidth="150px"
                prop="settleProjectNum"
              />
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settleNum"
                min-width="100px"
              />
              <el-table-column
                label="收款金额"
                align="left"
                show-overflow-tooltip
                prop="totalFee"
              />
              <el-table-column
                label="到账金额"
                align="left"
                show-overflow-tooltip
                prop="accountFee"
              />
              <el-table-column
                label="应付课程费"
                align="left"
                show-overflow-tooltip
                prop="courseFee"
                min-width="100px"
              />
              <el-table-column
                label="实付课程费"
                align="left"
                show-overflow-tooltip
                prop="payedCourseFee"
                min-width="80px"
              />
              <el-table-column
                label="应付代理费"
                align="left"
                show-overflow-tooltip
                prop="agencyFee"
                min-width="150px"
              />
              <el-table-column
                label="实付代理费"
                align="left"
                show-overflow-tooltip
                prop="payedAgencyFee"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.payedAgencyFee }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "InstitutionalSettlementStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      auditType: "", //结算单状态
      areaId: "", //行政区划
      trainTypeId: "", //培训类型
      billing: "", //是否开票
      signingTime: "", //班级结束日期
      compId: "",
      settleState: "", //班级结清状态
      billingTypeList: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      settleStateList: [
        {
          label: "未结清",
          value: "0",
        },
        {
          label: "已结清",
          value: "1",
        },
      ],
      trainTypeList: [],
      areatreeList: [],
      statementList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      CompanyList: [],
      CompanyactualList: [],
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getareatree();
    this.getTraintype();
    this.getStatusList();
    this.getTableHeight();
  },
  methods: {
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 获取 - 结算单状态
    getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
        list.push({
          value: key,
          label: statusList[key],
        });
      }
      this.statementList = list;
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isInvoice: this.billing,
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.auditType) {
        params.auditType = this.auditType;
      }
      if (this.settleState) {
        params.settleState = this.settleState;
      }
      if (this.signingTime) {
        params.endDateStart = this.signingTime[0];
        params.endDateEnd = this.signingTime[1];
      }
      this.doFetch({
        url: "/biz/new/bill/settleStatistics/pageList",
        params,
        pageNum,
      });
    },
    getExport() {
      const params = {
        isInvoice: this.billing,
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.auditType) {
        params.auditType = this.auditType;
      }
      if (this.settleState) {
        params.settleState = this.settleState;
      }
      if (this.signingTime) {
        params.endDateStart = this.signingTime[0];
        params.endDateEnd = this.signingTime[1];
      }
      this.$post("/biz/new/bill/settleStatistics/exportPageList", params).then(
        (res) => {
            if (res.status == "0") {
              let list = res.data;
              for (let item of list) {
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(re.message);
            }
          window.open(ret.data);
        }
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    /* 清空 */
    // clearSearch() {
    //   this.contractName = "";
    //   this.contractType = "";
    //   this.signCompId = "";
    //   this.actualCompId = "";
    //   this.settleState = "";
    //   this.signingTime = "";
    //   this.salesman = "";
    // },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.query.refresh == true) {
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
</style>
